<template>
  <div style="height: 100%; width: 100%; position: absolute; top: 0; bottom: 0; right: 0;">
    <l-map :zoom="zoom" :center="[42.869717, 74.607213]" @update:zoom="zoomUpdate">
      <l-tile-layer :url="url" :attribution="attribution"/>
      <l-control :class="$vuetify.breakpoint.xs ? 'pa-0 ma-0' : ''" class="example-custom-control">
        <v-btn v-show="$vuetify.breakpoint.xsOnly" small class="px-0" @click="mob = !mob">
          <v-icon>{{ mob ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
        </v-btn>
        <v-card :class="{'test-hide': mob}" max-width="200px" flat class="mx-6 mb-2">
          <v-radio-group v-model="selectStore" dense hide-details>
            <v-radio value="showStores" label="Все заведения"></v-radio>
            <v-radio value="showActiveStores" label="Активные заведения"></v-radio>
          </v-radio-group>
          <v-expansion-panels multiple flat v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="ma-0 pa-0" ripple>
                <v-checkbox v-model="showDrivers" label="Все курьеры" @click="selectDrivers"/>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="ma-0 pa-0">
                <v-row v-for="driver in drivers" :key="driver.id" class="d-flex align-center my-1">
                  <v-checkbox :value="driver" v-model="selectedDrivers" class="ma-0 pa-0" hide-details/>
                  <span>{{ driver.name }}</span>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="ma-0 pa-0" ripple>
                <v-checkbox v-model="showOrders" label="Все заказы" @click="selectOrders"/>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-for="order in orders" :key="order.id" class="d-flex align-center my-1">
                  <v-checkbox :value="order" v-model="selectedOrders" class="ma-0 pa-0" hide-details/>
                  <span>{{ order.number }}</span>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </l-control>

      <div v-if="selectStore === 'showStores'">
        <l-marker v-for="store in stores" :key="store.id" :lat-lng="[store.lat,	store.lng]">
          <l-popup>
            <div class="text-center font-weight-bold">{{ store.name }}</div>
            <div class="text-center font-weight-bold">{{ store.phone | normalizePhone }}</div>
            <div class="text-center font-weight-bold">{{ store.address }}</div>
          </l-popup>
          <l-icon>
            <v-row align="center" justify="center">
              <v-avatar v-if="store.logo" :size="size" class="primary lighten-2 opacity-50">
                <img alt :src="logo(store.logo)">
              </v-avatar>
            </v-row>
          </l-icon>
        </l-marker>
      </div>

      <div v-if="selectStore === 'showActiveStores'">
        <l-marker v-for="store in activeStores" :key="store.id" :lat-lng="[store.lat,	store.lng]">
          <l-popup>
            <div class="text-center font-weight-bold">{{ store.name }}</div>
            <div class="text-center font-weight-bold">{{ store.phone | normalizePhone }}</div>
            <div class="text-center font-weight-bold">{{ store.address }}</div>
          </l-popup>
          <l-icon>
            <v-row align="center" justify="center">
              <v-avatar v-if="store.logo" size="46" class="primary lighten-2 opacity-50">
                <img alt :src="logo(store.logo)">
              </v-avatar>
            </v-row>
          </l-icon>
        </l-marker>
      </div>

      <div>
        <l-marker v-for="row in selectedDrivers" :key="row.id" :lat-lng="[row.lat, row.lng]">
          <l-popup>
            <div class="text-center font-weight-bold">Заказов: {{ row.count }}</div>
            <div class="text-center font-weight-bold">{{ row.name }}</div>
            <div class="text-center font-weight-bold">{{ row.phone | normalizePhone }}</div>
            <div class="text-center font-weight-bold">Выкуп: {{ row.cashOnDelivery }}</div>
            <div class="text-center font-weight-bold">На комиссию: {{ row.available_funds }}</div>
            <div v-if="row.status === 'ACTIVE'" class="text-center font-weight-bold">
              <v-chip color="green" dark close
                      @click:close="remove(row.shift_id)">
                На смене
              </v-chip>
            </div>
            <div v-else-if="row.finished_reason === 'LUNCH'" class="text-center font-weight-bold">
              <v-chip color="orange" dark close
                      @click:close="remove(row.shift_id)">
                На обеде
              </v-chip>
            </div>
            <div v-else-if="row.finished_reason === 'FORCE_MAJEURE'" class="text-center font-weight-bold">
              <v-chip color="red" dark close
                      @click:close="remove(row.shift_id)">
                Форс-мажор
              </v-chip>
            </div>
            <div v-else class="text-center font-weight-bold">
              <v-chip color="rgba(135,135,135,1)" dark close>
                Завершил смену
              </v-chip>
            </div>
            <v-avatar v-if="row.photo" size="96px" class="primary">
              <img alt :src="logo(row.photo)">
            </v-avatar>
          </l-popup>
          <l-icon>
            <v-row align="center" justify="center">
              <v-avatar v-if="row.vehicle_icon" size="46" :class="`${shiftStatus(row)}`">
                <img alt :src="logo(row.vehicle_icon)">
              </v-avatar>
            </v-row>
          </l-icon>
        </l-marker>
      </div>

      <div>
        <l-marker v-for="order in selectedOrders" :key="order.id" :lat-lng="[order.lat,	order.lng]"
                  @dblclick="getPoints(order)">
          <l-popup>
            <div class="text-center font-weight-bold">№ {{ order.number }}</div>
            <div>
              <span class="font-weight-bold">{{ order.client.name ? order.client.name : "Без имени" }}</span>
              <span class="font-weight-bold ml-3">{{ order.client.phone | normalizePhone }}</span>
            </div>
            <div class="text-center font-weight-bold">{{ order.address }}</div>
            <div class="text-center font-weight-bold">{{ order.description }}</div>
            <div class="text-center font-weight-bold">{{ order.driver_comment }} {{ order.store_comment }}</div>
            <div class="text-center font-weight-bold" v-if="order.driver">{{ order.driver.name }}</div>
            <div class="text-center font-weight-bold" v-if="order.driver">{{
                order.driver.phone | normalizePhone
              }}
            </div>
          </l-popup>
          <l-icon>
            <v-row align="center" justify="center">
              <v-avatar size="46">
                <img alt="" :src=order_logo(order)>
              </v-avatar>
            </v-row>
          </l-icon>
        </l-marker>
        <div>
          <l-polyline :lat-lngs="points" color="black" :weight="weight"/>
        </div>
      </div>
    </l-map>
  </div>
</template>

<script>
import {LControl, LIcon, LMap, LMarker, LPolyline, LPopup, LTileLayer} from "vue2-leaflet";
import axios from "../../services/axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControl,
    LPolyline
  },
  data() {
    return {
      mob: true,
      selectStore: 'showActiveStores',
      panel: [0, 1],
      zoom: 13,
      weight: 10,
      currentZoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      stores: [],
      drivers: [],
      orders: [],
      showDrivers: false,
      selectedDrivers: [],
      showOrders: false,
      selectedOrders: [],
      points: []
    };
  },
  created() {
    axios.send({
      url: "/monitor/stores",
      method: "GET",
    }).then((resp) => {
      this.stores = resp.data;
    });

    axios.send({
      url: "/monitor/drivers",
      method: "GET",
    }).then((resp) => {
      this.drivers = resp.data;
      this.selectedDrivers = resp.data;
      this.showDrivers = true;
    });

    axios.send({
      url: "/monitor/orders",
      method: "GET",
    }).then((resp) => {
      this.orders = resp.data;
      this.selectedOrders = resp.data;
      this.showOrders = true;
    });
  },

  computed: {
    activeStores() {
      return this.stores.filter((store) => this.orders.some(order => store.id === order.store_id));
    },
    size() {
      return ((this.currentZoom - this.zoom) > 1 ? (this.currentZoom - this.zoom) : 1) * 20;
    },
  },
  methods: {
    selectDrivers() {
      this.selectedDrivers = [];
      if (this.showDrivers) {
        for (let i in this.drivers) {
          this.selectedDrivers.push(this.drivers[i]);
        }
      }
    },
    selectOrders() {
      this.selectedOrders = [];
      if (this.showOrders) {
        for (let i in this.orders) {
          this.selectedOrders.push(this.orders[i]);
        }
      }
    },
    logo(val) {
      if (val) {
        return val;
      } else {
        return "/silverware.svg"
      }
    },
    order_logo(val) {
      if (val.driver_started_at) {
        return "/order-client.svg"
      } else if (val.driver.phone) {
        return "/order-store.svg"
      }

      return "/order-new.svg"
    },
    status(item) {
      if (item.finished_at) {
        return "finished"
      }
      if (item.store_started_at) {
        return "progress"
      }

      return "started"
    },
    shiftStatus(driver) {
      if (driver.status === 'NEW') {
        return "red"
      } else if (driver.finished_reason === 'LUNCH') {
        return "orange"
      } else if (driver.finished_reason === 'FORCE_MAJEURE') {
        return "red"
      } else if (driver.count < 1) {
        return "green"
      } else if (driver.count < 2) {
        return "blue lighten-4"
      }
      return "blue"
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    getPoints(order) {
      this.points = [];

      if (this.drivers && order.driver.phone && this.drivers.length > 0) {
        this.drivers.forEach(d => {
          if (d.id === order.driver.id) {
            this.points.push([d.lat, d.lng])
          }
        })
      }

      if (!order.driver_started_at) {
        if (this.stores && order.store_id && this.stores.length > 0) {
          this.stores.forEach(s => {
            if (s.id === order.store_id) {
              this.points.push([s.lat, s.lng])
            }
          })
        }
      }
      this.points.push([order.lat, order.lng])
    },
    remove(id) {
      axios.send({
        url: "/status/drivers/" + id,
        method: "DELETE",
      }).then(() => {
        location.reload();
      });
    },
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
  },
};
</script>

<style scoped>

@media (max-width: 600px) {
  .test-hide {
    display: none;
  }
}

.example-custom-control {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}

</style>
